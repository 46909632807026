'use client';
import { createContext, useState } from 'react';
import * as ga from '../lib/ga';

const CSPContext = createContext();

const validCSPFinderFilters = [
  'isFreeTrial',
  'verticalMarket',
  'countries',
  'complexSearchCSP',
  'providerServices',
  'servicesProvided',
  'isLicensePortability'
];

export function CSPListingsProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [state, setState] = useState({ status: '', invalidFields: [] });
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [randomized, setRandomized] = useState(null);

  const [f, setF] = useState('nan');
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingURL, setLoadingURL] = useState(true);
  const [isLoadingMenu, setIsLoadingMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whereToComeBack, setWhereToComeBack] = useState({});
  const [requiredFields, setRequiredFields] = useState([]);
  const [fieldsValidity, setFieldsValidity] = useState([]);

  //Used in localStorage with the key 'isFirstWebsiteVisit' to only show the search tooltip on the first visit
  const [isFirstWebsiteVisit, setFirstWebsiteVisit] = useState();

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };
      if (
        [
          'verticalMarket',
          'countries',
          'providerServices',
          'servicesProvided'
        ].includes(prop)
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      } else if (['complexSearchCSP'].includes(prop)) {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      }
      return objCopy;
    });
  }

  function clearFilters() {
    setFilters({});
  }

  function clearCSPFiltersExceptSearch() {
    if (filters.complexSearchCSP)
      setFilters({ complexSearchCSP: filters.complexSearchCSP });
    else setFilters({});
  }

  function clearFinderToolRandomization() {
    setRandomized(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
  }

  const totalFilters = Object.entries(filters)
    ?.filter((el) => validCSPFinderFilters.includes(el[0]))
    ?.reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  const visibleTotalFilters = totalFilters;

  const context = {
    filters,
    totalFilters,
    visibleTotalFilters,
    updateFilters,
    setFilters,
    clickOnFilter,
    clearFilters,
    clearCSPFiltersExceptSearch,
    currentPage,
    setCurrentPage,
    currentPageFiltering,
    setCurrentPageFiltering,
    f,
    setF,
    loadingInfo,
    setLoadingInfo,
    state,
    setState,
    isFirstWebsiteVisit,
    setFirstWebsiteVisit,
    isLoadingMenu,
    setIsLoadingMenu,
    isOpen,
    setIsOpen,
    whereToComeBack,
    setWhereToComeBack,
    requiredFields,
    setRequiredFields,
    loadingURL,
    setLoadingURL,
    validCSPFinderFilters,
    randomized,
    setRandomized,
    clearFinderToolRandomization,
    clearFinderToolPagination,
    fieldsValidity,
    setFieldsValidity
  };

  return <CSPContext.Provider value={context}>{children}</CSPContext.Provider>;
}

export default CSPContext;
