'use client';
import {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useState,
  SetStateAction
} from 'react';
import { getSession } from 'next-auth/react';
import { getDetailedUser } from 'shared/session';

type ContextProps = {
  user: any | null;
  userPermissions: any;
  selfPartner: any[];
  selfKnight: any[];
  allocatedPartners: any[];
  isPartnerAdmin: boolean;
  isSiteAdmin: boolean;
  isPartnerAccountDirector: boolean;
  isAuthenticated: boolean;
  partnersWithProductForecastAccess: number[];
  setAuthPartnerId: Dispatch<SetStateAction<number | null>>;
  setUserPermissions: Dispatch<SetStateAction<any>>;
};

export const AuthContext = createContext<ContextProps>({} as ContextProps);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<any | null>(null);
  const [userPermissions, setUserPermissions] = useState<any>({
    loaded: false
  });
  const [selfPartner, setSelfPartner] = useState<any[]>([]);
  const [selfKnight, setSelfKnight] = useState<any[]>([]);
  const [allocatedPartners, setAllocatedPartners] = useState<any[]>([]);
  const [isPartnerAdmin, setIsPartnerAdmin] = useState(false);
  const [isProductForecastManager, setIsProductForecastManager] =
    useState(false);
  const [isSiteAdmin, setIsSiteAdmin] = useState(false);
  const [isPartnerAccountDirector, setIsPartnerAccountDirector] =
    useState(false);
  const [authPartnerId, setAuthPartnerId] = useState<number | null>(null);
  const isAuthenticated: boolean = !!user;

  const productForecastsAccessIds = [
    10, 26, 13, 5, 1, 11, 14, 125, 15, 55, 2, 19, 105, 6, 131, 94, 54, 25, 133, 59,
    36,
    1,
    72,
    52,
    74,
    132,
    28
  ];

  useEffect(() => {
    async function getAuthStatus() {
      const session: any = await getSession();

      if (!session || !session?.user) return;

      const {
        user,
        allocatedPartners,
        isPartnerAccountDirector,
        isPartnerAdmin,
        isProductForecastManager,
        isSiteAdmin,
        selfKnight,
        selfPartner,
        userPermissions
      } = getDetailedUser(session, authPartnerId);

      setUser(user);
      setUserPermissions(userPermissions);
      setSelfPartner(selfPartner);
      setSelfKnight(selfKnight);
      setAllocatedPartners(allocatedPartners);
      setIsPartnerAdmin(isPartnerAdmin);
      setIsSiteAdmin(isSiteAdmin);
      setIsProductForecastManager(isProductForecastManager);
      setIsPartnerAccountDirector(isPartnerAccountDirector);
    }

    getAuthStatus();
  }, [authPartnerId]);

  const values = {
    user,
    userPermissions,
    setUserPermissions,
    selfPartner,
    allocatedPartners,
    selfKnight,
    isPartnerAdmin,
    isSiteAdmin,
    isProductForecastManager,
    isPartnerAccountDirector,
    isAuthenticated,
    partnersWithProductForecastAccess: productForecastsAccessIds,
    setAuthPartnerId
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
